import React, { useState, useEffect, useContext, useRef } from "react";
import { Drawer, Menu, Table, Button, Row, Select, Input, Col, Skeleton, Modal, Form, Checkbox, Space } from "antd";
import useHttp from "../../hooks/useHttp";
import "../../assets/css/buyNumber.css";
import { AppContext } from "../../context/AppContext";
import contactIcon from "../../assets/icons/contactIcon.png";
import { RefreshLicenseDetails } from "../utils/CommonVessels";
import LocationIcon from "../../assets/icons/locationIcon.png";
import mmsIcon from "../../assets/icons/mmsIcon.png";
import smsIcon from "../../assets/icons/smsIcon.png";
import CoinsIcon from "../../assets/icons/Coins.png";
import { FlagOutlined, UserOutlined, CloseOutlined } from "@ant-design/icons";
import { Chargebee_CHARGE_NAME, urlParams, ASSIST_MAIL } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { FundsMonitor, NotEnoughCredits } from "../utils/FundsMonitor";
import { successNotification } from "../utils/CommonNotifications";

const columns = [
  { title: "Phone number", dataIndex: "friendlyName", key: "friendlyName" },
  { title: "Funds", dataIndex: "credits", key: "credits" },
  { title: "", dataIndex: "buy", key: "buy" },
];

const BuyNumber = (props) => {
  const creditsRef = useRef(0);
  const [showNoCredits, setShowNoCredits] = useState(false);
  const [form] = Form.useForm();
  const [fillform] = Form.useForm();
  const { fetchData } = useHttp();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const leftServiceId = appProperties.leftServiceId;
  const [contentToShow, setContentToShow] = useState(false);
  const [menuKey, setMenuKey] = useState("local");
  const [tableData, setTableData] = useState([]);
  const [selectRegion, setSelectRegion] = useState("US");
  const [selectMethod, setSelectMethod] = useState("localities");
  const [supportedCountryList, setSupportedCountryList] = useState([]);
  const [controlApi, setControlApi] = useState(false);

  const [countryList, setCountryList] = useState([
    {
      label: "US",
      value: "US",
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [filter, setFilter] = useState("contains");
  const [loading, setLoading] = useState(false);
  const [rightServiceObj, setRightServiceObj] = useState({});
  const [chargeBeePlan] = useState(Chargebee_CHARGE_NAME);
  const [unSupportedCountryList, setUnSupportedCountryList] = useState(false);
  const [getNumberModalOpen, setGetNumberModalOpen] = useState(false);
  const [alreadyRegisteredFromModal, setAlreadyRegisteredFromModal] = useState("false");

  useEffect(() => {
    if (appProperties?.licenseObj?.licenseDetails) {
      let { creditsConsumed, creditsPurchased } = appProperties.licenseObj.licenseDetails;
      let balanceCredits = creditsPurchased - creditsConsumed;
      creditsRef.current = Number.parseFloat(balanceCredits);
      if (creditsRef.current > 5) {
        setShowNoCredits(false);
      }
    }
  }, [
    appProperties.licenseObj,
    appProperties.licenseObj?.licenseDetails?.creditsPurchased,
    appProperties.licenseObj?.licenseDetails?.creditsConsumed,
  ]);
  useEffect(() => {
    let queryObj = appProperties?.installedApps?.find((app) => app?.right?.service_name === "hello_send");

    if (queryObj) {
      queryObj = {
        integId: queryObj?.integProps?.integId,
        serviceId: queryObj?.right?.service_id,
        isTrialAccount: queryObj?.right?.isTrialAccount,
        rightServiceName: queryObj?.right?.service_name,
        authorized: queryObj?.right?.auth?.authorized,
      };
    }
    setRightServiceObj(queryObj);
  }, [appProperties.installedApps]);

  useEffect(() => {
    if (rightServiceObj?.integId) {
      let apiUrl =
        "hellosend/account/" +
        rightServiceObj.integId +
        "/available/numbers?" +
        "leftServiceId=" +
        leftServiceId +
        "&numberType=" +
        menuKey +
        "&countryCode=" +
        selectRegion +
        "&searchType=" +
        selectMethod +
        "&searchText=" +
        filterInput?.trim() +
        "&filter=" +
        filter;
      setLoading(true);

      if (props.open === true) {
        fetchData(apiUrl, "GET", null, appProperties)
          .then((response) => {
            try {
              if (response) {
                response = JSON.parse(response);
                let availableNumberList = response?.data?.numbers ?? [];
                let availableCountryList = response?.data?.countries ?? [];
                let countryNames = availableCountryList.map((service) => service.country);
                const antdSelectOptions = countryNames.map((country) => ({
                  label: country.country,
                  value: country.code,
                }));
                const supportedCountries = availableCountryList
                  .filter((service) => service)
                  .map((service) => ({
                    label: service.country,
                    value: service.code,
                    supported: service?.supported,
                  }));

                setSupportedCountryList(supportedCountries);
                setCountryList(antdSelectOptions);
                const localData = mapDataTotable(availableNumberList);
                setTableData(localData);
                setContentToShow(true);
                setLoading(false);
                if (response?.data?.license) {
                  setAppProperties((prev) => ({
                    ...prev,
                    licenseObj: {
                      ...prev.licenseObj,
                      licenseDetails: response.data?.license,
                    },
                  }));
                }
                setControlApi(true);
              } else {
                setLoading(false);
                setContentToShow(true);
              }
            } catch (e) {
              setLoading(false);
              setContentToShow(true);
            }
          })
          .catch((error) => {
            setLoading(false);
            setContentToShow(true);
          });
      }
    }
  }, [props.open, rightServiceObj]);

  const handleMenuClick = ({ key }) => {
    setMenuKey(key);
  };
  function callNumbersApi() {
    let queryParams =
      "?leftServiceId=" +
      leftServiceId +
      "&numberType=" +
      menuKey +
      "&countryCode=" +
      selectRegion +
      "&searchType=" +
      selectMethod +
      "&searchText=" +
      filterInput?.trim() +
      "&filter=" +
      filter;
    let url = "hellosend/account/" + rightServiceObj?.integId + "/available/numbers" + queryParams;
    let body = {};
    if (controlApi === true) {
      setLoading(true);
      fetchData(url, "GET", body, appProperties)
        .then(function (response) {
          if (response) {
            response = JSON.parse(response);
            if (response?.data?.numbers?.length > 0) {
              let numberList = response.data.numbers;
              setTableData(mapDataTotable(numberList));
            } else {
              setTableData(response?.data?.numbers ?? []);
            }
            setLoading(false);
          } else {
            setLoading(false);
            setContentToShow(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("ERROR ::::: available Numbers    ", err);
        });
    }
  }

  useEffect(() => {
    callNumbersApi();
  }, [selectRegion, menuKey]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      callNumbersApi();
    }
  };

  function mapDataTotable(data) {
    const updatedData = data.map((obj, index) => {
      return {
        ...obj,
        friendlyName: (
          <div className="numberList">
            <span className="buyNumberHeaderTextColor fw-bold">{obj.friendlyName}</span>
            <span style={{ color: "#90A0B7" }}>
              <br />
              {obj.locality !== undefined && obj.locality !== "" ? obj.locality + "," : ""}{" "}
              {obj.state !== undefined && obj.state !== "" ? obj.state + "," : ""} {obj.country}
            </span>
          </div>
        ),
        credits: <div className="creditsBuyNumber buyNumberHeaderTextColor">${obj.credits}</div>,
        buy: (
          <Button
            onClick={() => handleBuyClick(obj)}
            type="text"
            id={"buyButtonList" + index}
            style={{
              color: "#26C0E2",
              borderRadius: 8,
              width: 61,

              background: "rgba(38, 192, 226, .1)",
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Buy
          </Button>
        ),
      };
    });
    return updatedData;
  }

  const handleBuyClick = (obj) => {
    setSelectedNumber(obj);
    const currentCredits = creditsRef.current;
    if (currentCredits > obj.credits && currentCredits > 5) {
      setIsModalOpen(true);
    } else {
      setShowNoCredits(true);
    }
  };
  function handleNoCreditsCancel() {
    setShowNoCredits(false);
  }
  function handleModalClose() {
    setUnSupportedCountryList(false);
  }

  return (
    <>
      <NotEnoughCredits
        open={showNoCredits}
        handleNoCreditsCancel={handleNoCreditsCancel}
        chargeBeePlan={chargeBeePlan}
        alreadyRegistered={alreadyRegisteredFromModal}
        setAlreadyRegistered={setAlreadyRegisteredFromModal}
        appProperties={appProperties}
        setAppProperties={setAppProperties}
        showNoCredits={showNoCredits}
        setShowNoCredits={setShowNoCredits}
      />
      <Drawer
        extra={
          <Space className="d-flex">
            <RefreshLicenseDetails usersTabRefreshBtn={false} />
            <FundsMonitor />
            {props.closeDrawer && (
              <Button
                shape="circle"
                type=""
                className="close-btn"
                onClick={() => {
                  if (props.closeDrawer && typeof props.closeDrawer === "function") {
                    props.closeDrawer();
                    setFilterInput("");
                  } else {
                  }
                }}
                icon={<CloseOutlined />}
              ></Button>
            )}
          </Space>
        }
        className="buyNumberDrawer"
        title={<span className="buyNumberHeaderSpan buyNumberHeaderTextColor">Choose a number</span>}
        placement="right"
        open={props.open}
        width={700}
        closable={false}
        onClose={() => {
          if (props.closeDrawer && typeof props.closeDrawer === "function") {
            props.closeDrawer();
            setFilterInput("");
          } else {
          }
        }}
      >
        {contentToShow ? (
          <>
            <Menu className="buyNumberMenu" mode="horizontal" selectedKeys={[menuKey]} onClick={handleMenuClick}>
              <Menu.Item className="buyNumberMenuItem ps-5 pe-5" key="local">
                Local
              </Menu.Item>
              <Menu.Item className="buyNumberMenuItem ps-5 pe-5" key="toll-free">
                Toll-free
              </Menu.Item>
            </Menu>
            <Row className="inputsRow buyNumberCountrySelection w-100 pt-3 pb-3  d-flex justify-content-evenly">
              <Col className="pt-2 mt-1 pb-2">
                <Select
                  value={selectRegion}
                  onChange={(e) => {
                    const selectedCountry = supportedCountryList.find((obj) => obj.value === e);
                    const isSupportedRegion = selectedCountry ? selectedCountry.supported : false;
                    if (!isSupportedRegion) {
                      setUnSupportedCountryList(true);
                    } else {
                      setSelectRegion(e);
                    }
                  }}
                  className="p-0 m-0 countrySelectList"
                  options={supportedCountryList}
                  variant="borderless"
                  popupMatchSelectWidth={false}
                />
                {}
                <Modal
                  title={
                    <Row>
                      <div className="d-flex justify-content-center w-100">
                        <span
                          className="d-flex justify-content-center actionIconsSprite phoneNumberScheduleIcon"
                          style={{ width: 60, height: 60 }}
                        ></span>
                      </div>
                    </Row>
                  }
                  centered
                  visible={unSupportedCountryList}
                  width={400}
                  closeIcon={<div className="actionIconsSprite modalCloseIcon hs-min-w-30-px" />}
                  onOk={() => setUnSupportedCountryList(false)}
                  onCancel={() => setUnSupportedCountryList(false)}
                  footer={null}
                >
                  <div className="mt-4 mb-4">
                    <Row>
                      <div className="d-flex justify-content-center w-100">
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "bolder",
                            color: "#111827",
                          }}
                        >
                          Get a Phone Number
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <div>
                        <span className="mt-1 d-flex justify-content-center">
                          <p
                            className="text-center"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#374151",
                            }}
                          >
                            Schedule an appointment with us to acquire a phone number for your chosen country.
                          </p>
                        </span>
                      </div>
                    </Row>
                  </div>
                  <div className="d-flex justify-content-center justify-content-between">
                    <Button className="h-45  commonGhostButton text-dark" onClick={handleModalClose} style={{ fontWeight: 600, width: 170 }}>
                      Cancel
                    </Button>
                    <Button
                      className="hs-bg-violet hs-border-10 big-button "
                      type="primary"
                      name="submitButton"
                      tabIndex={6}
                      loading={loading}
                      style={{ fontWeight: 600, width: 170 }}
                      onClick={() => {
                        setLoading(true);
                        setGetNumberModalOpen(true);
                        // Simulate a network request
                        setTimeout(() => {
                          setLoading(false);
                          setUnSupportedCountryList(false);
                        }, 2000);
                      }}
                    >
                      <span className="px-4">Schedule</span>
                    </Button>
                  </div>
                </Modal>
                {getNumberModalOpen && (
                  <Modal
                    title=" "
                    centered
                    open={getNumberModalOpen}
                    onOk={() => setGetNumberModalOpen(false)}
                    onCancel={() => setGetNumberModalOpen(false)}
                    width={1000}
                    footer={null}
                  >
                    <div>
                      <iframe
                        className="iframe-popup"
                        src={`https://oapps.zohobookings.com/portal-embed#/numberacquisition?Name=${appProperties?.userName}&Email=${appProperties?.currentUserEmail}`}
                        frameBorder="0"
                        allowFullScreen
                        title="Schedule"
                      />
                    </div>
                  </Modal>
                )}
              </Col>
              <Row className="selectCombo flex-grow-1 pt-2 mt-1 pb-2 mb-1">
                <Col>
                  <Select
                    className="selectTypeSelect"
                    style={{ width: "80% !important" }}
                    options={[
                      {
                        value: "localities",
                        label: "Localities",
                      },
                      {
                        value: "number",
                        label: "Number",
                      },
                    ]}
                    value={"Search by " + selectMethod}
                    onChange={(e) => {
                      setSelectMethod(e);
                      setFilterInput("");
                      setFilter("contains");
                    }}
                    variant="borderless"
                  />
                  <Select
                    className="selectTypeSelect"
                    style={{ width: "80% !important" }}
                    options={[
                      {
                        value: "contains",
                        label: "Contains",
                      },
                      ...(selectMethod === "number"
                        ? [
                            {
                              value: "starts_with",
                              label: "Starts with",
                            },
                            {
                              value: "ends_with",
                              label: "Ends with",
                            },
                          ]
                        : []),
                    ]}
                    value={filter}
                    onChange={(e) => {
                      setFilter(e);
                    }}
                    variant="borderless"
                  />
                </Col>
                <Col className="flex-grow-1">
                  <Input
                    onChange={(e) => {
                      setFilterInput(e.target.value);
                    }}
                    value={filterInput}
                    className="localInput"
                    placeholder={"Search by " + selectMethod}
                    onKeyDown={handleKeyPress}
                  />
                </Col>
              </Row>
            </Row>
            <Table loading={loading} className="buyNumberTable" pagination={false} dataSource={tableData} columns={columns} />
            <NumberVerifyModal
              rightServiceObj={rightServiceObj}
              updateTableData={props.updateTableData}
              tableData={props.tableData}
              setIsModalOpen={setIsModalOpen}
              country={countryList}
              form={form}
              fillform={fillform}
              isModalOpen={isModalOpen}
              selectedNumber={selectedNumber}
              closeDrawer={props.closeDrawer}
              setFilterInput={setFilterInput}
            />
          </>
        ) : (
          <div style={{ padding: "2em", height: "100vh" }}>
            <Skeleton paragraph={{ rows: 7 }} active />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default BuyNumber;

export function NumberVerifyModal(props) {
  const { fetchData } = useHttp();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [addressRequired, setAddressRequired] = useState(false);
  const [enableReview, setEnableReview] = useState(true);
  const [friendlyName, setFriendlyName] = useState("");
  const [detailMenuKey, setDetailMenuKey] = useState("");
  const [provisionBtnLoading, setProvisionBtnLoading] = useState(false);
  const capabilities = props.selectedNumber.capabilities;
  const form = props.form;
  const integId = props.rightServiceObj?.integId;
  const navigate = useNavigate();
  useEffect(() => {
    if (props.selectedNumber.addressRequirment === "none") {
      setAddressRequired(false);
      setDetailMenuKey("reviewPhone");
      setEnableReview(false);
    } else {
      setAddressRequired(true);
      setDetailMenuKey("personalDetails");
    }
  }, [props]);
  const handleCancel = () => {
    props.setIsModalOpen(false);
    form.resetFields();
  };
  function handleCreateBtn() {
    setDetailMenuKey("personalDetails");
    setEnableReview(false);
    form.validateFields().then((values) => {
      let apiUrl = "hellosend/account/" + integId + "/address";
      fetchData(apiUrl, "POST", values, appProperties).then(function (response) {
        setDetailMenuKey("personalDetails");
        setEnableReview(false);
      });
    });
  }

  function handleDetailsMenu({ key }) {
    setDetailMenuKey(key);
  }

  function buyProvisionNumber() {
    if (friendlyName !== "") {
      setProvisionBtnLoading(true);
      let userId = urlParams.get("userId");
      let emailId = urlParams.get("email");
      let body = {
        phoneNumber: props.selectedNumber.number,
        numberType: props.selectedNumber.type,
        country: props.selectedNumber.country,
        alphaSender: "false",
        isMessagingService: "false",
        friendlyName: friendlyName,
        addressId: "",
        bundleSid: "",
      };
      fetchData("hellosend/account/" + integId + "/provision/number?userId=" + userId + "&emailId=" + emailId, "POST", body, appProperties)
        .then(function (response) {
          response = JSON.parse(response);
          let license = response.data.license;
          let newData = response.data.phone;
          console.log("newData", newData);
          if (props.updateTableData !== undefined) {
            successNotification("SMS number allocated. Complete 10DLC to activate SMS channel.");
            const updatedTableData = [...(props.tableData || []), newData];
            props.updateTableData(updatedTableData);
          } else {
            if (props?.tableData?.length === 0) {
              props.updateTableData([newData]);
            }
          }
          if (props.closeDrawer && typeof props.closeDrawer === "function") {
            props.closeDrawer();
            props.setFilterInput("");
          }
          setAppProperties((prev) => ({
            ...prev,
            licenseObj: {
              ...prev.licenseObj,
              licenseDetails: license,
            },
            allNumbers: [...(prev.allNumbers || []), newData],
          }));
          props.setIsModalOpen(false);
          setProvisionBtnLoading(false);
          navigate("/channels/textsms/helloSend?" + urlParams);
        })
        .catch((error) => {
          setProvisionBtnLoading(false);
        });
    }
  }
  return (
    <>
      <Modal closable={false} className="numberVerifyModal" centered open={props.isModalOpen} width={650} footer={null}>
        <Menu className="verifyMenu" mode="horizontal" selectedKeys={detailMenuKey} onClick={handleDetailsMenu}>
          {addressRequired === true ? (
            <Menu.Item key="personalDetails" className="personalDetailsMenu" style={{ cursor: "text" }}>
              Personal Details
            </Menu.Item>
          ) : null}
          <Menu.Item disabled={enableReview} key="reviewPhone" className="reviewPhoneMenu" style={{ cursor: "text" }}>
            Review Phone Number
          </Menu.Item>
        </Menu>

        {detailMenuKey === "personalDetails" && addressRequired === true && (
          <Form form={form}>
            <div className="personalDetailsForm">
              <span style={{ color: "#334D6E" }}>Full name*</span>
              <Form.Item
                name="customerName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter your full name"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  className="addressVerifyInput"
                />
              </Form.Item>
              <span style={{ color: "#334D6E" }}>Address*</span>
              <Form.Item
                name="street"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter your street name and house number"
                  prefix={<img src={contactIcon} alt="contactIcon" />}
                  className="addressVerifyInput"
                />
              </Form.Item>

              <span style={{ color: "#334D6E" }}>City*</span>
              <Form.Item name="city">
                <Input placeholder="City" prefix={<img src={LocationIcon} alt="locationIcon" />} className="addressVerifyInput2" />
              </Form.Item>
              <span style={{ color: "#334D6E" }}>State/Province/Region*</span>
              <Form.Item name="region">
                <Input
                  type="dropDown"
                  placeholder="Select Region"
                  prefix={<img src={LocationIcon} alt="locationIcon" />}
                  className="addressVerifyInput2"
                />
              </Form.Item>
              <span style={{ color: "#334D6E" }}>Postal Code*</span>
              <br />
              <Form.Item name="postalCode">
                <Input
                  placeholder="Enter your postal code"
                  prefix={<img src={LocationIcon} alt="locationIcon" />}
                  className="addressVerifyInput2"
                />
              </Form.Item>

              <span style={{ color: "#334D6E" }}>Country*</span>
              <Form.Item name="isoCountry">
                <Select
                  options={props.country}
                  prefix={<FlagOutlined className="site-form-item-icon" />}
                  placeholder="Select Country"
                  className="addressVerifyInput"
                />
              </Form.Item>
              {/* <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Should accept agreement")),
                  },
                ]}
              >
                <Checkbox style={{ fontFamily: "Inter , sans-serif" }}>
                  Please check to acknowledge our{" "}
                  <a style={{ color: "#009EFD" }} href="">
                    Privacy & Terms Policy
                  </a>
                </Checkbox>
              </Form.Item> */}
              <div className="buyNumberFooter">
                <Button key="back" className="cancelBuyButton" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" className="createAddressBtn" onClick={handleCreateBtn}>
                  Create
                </Button>
              </div>
            </div>
          </Form>
        )}

        {detailMenuKey === "reviewPhone" && (
          <>
            <Form fillform={props.fillform}>
              <div className="reviewNumber container mt-5 ">
                <Row gutter={[16, 16]} style={{ color: "#334D6E", fontFamily: "Inter , sans-serif" }}>
                  <Col span={14}>
                    <h1 style={{ fontWeight: 900 }}>{props.selectedNumber.friendlyName}</h1>
                  </Col>
                  <Col span={10} className="mt-2">
                    <div className="d-flex align-items-center justify-end">
                      <span style={{ fontSize: 26, fontWeight: 700 }}>
                        $ {props.selectedNumber.credits}
                        <img src={CoinsIcon} alt="CoinsIcon" />/
                      </span>
                      monthly fee
                    </div>
                  </Col>
                </Row>
                <div style={{ marginTop: 25, color: "#90A0B7" }}>
                  <Space size={[8, 22]} wrap>
                    Capabilities: {"  "}
                    {capabilities !== undefined && capabilities.includes("SMS") ? (
                      <span>
                        <img style={{ height: 17, verticalAlign: "text-top" }} src={smsIcon} alt="smsIcon" /> SMS
                      </span>
                    ) : null}{" "}
                    {capabilities !== undefined && capabilities.includes("MMS") ? (
                      <span>
                        <img style={{ height: 17, verticalAlign: "text-top" }} src={mmsIcon} alt="mmsIcon" /> MMS
                      </span>
                    ) : null}
                  </Space>
                </div>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#90A0B7",
                    marginTop: 80,
                  }}
                >
                  You'll be billed ${props.selectedNumber.credits} upfront, and then an extra ${props.selectedNumber.credits} per month along
                  with any usage charges on the phone number.
                </div>
                <div className="friendlyName pb-5 mb-5">
                  <span>Friendly name*</span>
                  <br />
                  <Form.Item
                    name="friendlyName"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      defaultValue={friendlyName}
                      onChange={(e) => {
                        setFriendlyName(e.target.value);
                      }}
                      className="friendlyNameInput"
                      placeholder="Eg:US Support,Sale"
                    />
                  </Form.Item>
                </div>
                <div className="buyNumber mt-5 mb-5">
                  <Button key="back" className="cancelBuyButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Form.Item>
                    <Button htmlType="submit" loading={provisionBtnLoading} className="buyNumberBtn" type="" onClick={buyProvisionNumber}>
                      Buy {props.selectedNumber.number}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
}
