import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import Settings from "./Setting/Settings";
import Home from "./Home";
import Campaign from "./campaign/components/campaign/Campaign";
import CampaignOverview from "./campaign/components/overview/CampaignOverview";
import TextSMS from "./services/TextSMS";
import TextSMSAuthPage from "./services/TextSMSAuthPage";
import ActiveNumbers from "./OnboardingComponents/ActiveNumbers";
import ConversationView from "./ConversationPage";
import ConversationWrapper from "./ConversationContext";
import ShowAutomationComponentForm from "./../pages/automation/ShowAutomationComponentForm";
import ZohoCRMLandingPage from "./../pages/services/ZohoCRMLandingPage";
import SendSMS from "./outbound/SendSMS";
import Module from "./Module";
import AppLayout from "./AppLayout";
import Users from "./Users";
import BlockedNumbers from "./BlockedNumbers";
import AutomatedMessages from "./AutomatedMessages";
import ContentGpt from "./Content";
import CampaignAnalytics from "./CampaignAnalytics";
import TenDlc from "./OnboardingComponents/10DLC/TenDlc";
import Templates from "./Templates";
import GeneralSettings from "./Setting/GeneralAppsSettings";
import MessageLogs from "./MessageLogs";
import AutomationMessageLogs from "./automation/AutomationMessageLogs";
import VoiceTeam from "../voice/components/VoiceTeam";
import VoiceAnalytics from "../voice/components/VoiceAnalytics";
import CallHandlingFlowChart from "../voice/components/CallHandlingFlowChart";
import VoiceCallLog from "../voice/components/VoiceCallLog";
import { ReactFlowProvider } from "reactflow";
import Dialpad from "../voice/components/Dialpad";
import ChannelsConfigPage from "./OnboardingComponents/ChannelsConfiguration";
import NoAccess from "../pages/noAccess";

import { BackToChannelsButton } from "./services/TextSMSAuthPage";

const createRouter = (loadingContextValue, locationState, accessDenied, setAccessDenied) => {
  const { editNodes, editEdges } = locationState || { editNodes: [], editEdges: [] };
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<AppLayout setLoading={loadingContextValue?.setLoading} accessDenied={accessDenied} setAccessDenied={setAccessDenied} />}
      >
        <Route path="/channels" element={<ChannelsConfigPage />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/voice/analytics" element={<VoiceAnalytics />} />
        <Route path="/voice/teams" element={<VoiceTeam />} />
        <Route
          path="/voice/callflow/createflow"
          element={
            <ReactFlowProvider>
              <CallHandlingFlowChart />
            </ReactFlowProvider>
          }
        />
        <Route
          path="/voice/callFlow/edit/:callFlowId/phoneNumber/:phoneNumber"
          element={
            <ReactFlowProvider>
              <CallHandlingFlowChart editNodes={editNodes} editEdges={editEdges} />
            </ReactFlowProvider>
          }
        />
        <Route path="/voice/dialpad" element={<Dialpad showDialPad={true} />} />
        <Route path="/voice/history" element={<VoiceCallLog />} />
        <Route path="/settings/number/twilio_voice" element={<TextSMSAuthPage />} />
        <Route path="/settings/twilio" element={<TextSMSAuthPage />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/settings/general" element={<GeneralSettings />} />
        <Route path="/campaign" element={<Campaign />} />
        <Route path="/messaging/campaigns/list" element={<CampaignOverview />} />
        {/* <Route path="/channels" element={<ChannelsConfigPage />} /> */}
        <Route path="/settings/channels" element={<ChannelsConfigPage />} />
        <Route path="/settings/channels/whatsapp" element={<TextSMSAuthPage noStepsAuthPage={true} />} />
        <Route path="/channels/textsms" element={<TextSMS />} />
        <Route path="/settings/channels/textsms" element={<TextSMS />} />
        <Route path="/channels/facebook" element={<TextSMSAuthPage />} />
        <Route path="/channels/whatsapp" element={<TextSMSAuthPage noStepsAuthPage={true} />} />
        <Route path="/channels/textsms/helloSend" element={<ActiveNumbers backToChannelsButton={<BackToChannelsButton />} />} />
        <Route path="/channels/textsms/:dynamicSMSservice" element={<TextSMSAuthPage />} />
        <Route path="/settings/channels/textsms/:dynamicSMSservice" element={<TextSMSAuthPage />} />
        <Route path="/content" element={<ContentGpt />} />
        <Route path="/chat" element={<ConversationView />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} /> <Route path="/inbox" element={<ConversationWrapper />} />
        <Route
          path="/sendsms"
          element={<SendSMS bulk={false} appLoading={loadingContextValue.loading} setLoading={loadingContextValue?.setLoading} />}
        />
        <Route path="/messages" element={<SendSMS bulk={true} setLoading={loadingContextValue?.setLoading} />} />
        <Route path="/settings/templates" element={<Templates />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/module" element={<Module />} />
        <Route path="/settings/users" element={<Users />} />
        <Route path="/users" element={<Users />} />
        <Route path="/settings/blockedNumbers" element={<BlockedNumbers />} />
        {/* <Route path="/automation" element={<WorkFlow />} /> */}
        <Route path="/messaging/automation" element={<AutomatedMessages />} />
        <Route path="/messaging/automation/:eventId/logs" element={<AutomationMessageLogs />} />
        <Route path="/content" element={<ContentGpt />} />
        <Route path="/messaging/campaigns/list/:campaignId/reports" element={<CampaignAnalytics currentItem={"error"} />} />
        <Route path="/messaging/campaigns/list/:campaignId/overview" element={<CampaignAnalytics currentItem={"overview"} />} />
        <Route path="/messaging/campaigns" element={<Navigate to={`/messaging/campaigns/list${window.location.search}`} />} />
        <Route path="/channels/textsms/:serviceName/10DLC" element={<TenDlc />} />
        <Route path="/messaging/message-logs" element={<MessageLogs />} />
        <Route path="/zoho-crm/landing" element={<ZohoCRMLandingPage />} />
        <Route path="/teams/landing" element={<ZohoCRMLandingPage />} />
        <Route path="/zoho-crm/webhook" element={<ShowAutomationComponentForm source={"custom-action"} />} />
        <Route path="*" element={<h1>404!</h1>} />
        <Route path="no-access" element={<NoAccess />} />
      </Route>
    )
  );
};
export default createRouter;
