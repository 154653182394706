import React, { useContext, useEffect, useState } from "react";
import useHttp from "../../../hooks/useHttp";

import "../../../assets/css/tendlc.css";
import { Button, Form, Input, Radio, Select, Checkbox } from "antd";
import { AppContext } from "../../../context/AppContext";

function CreateA2PTrustProduct({ profileId, setCurrent, formFields, secondaryProfileStatus, trustType, integId }) {
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [companyType, setCompanytype] = useState(1);
  const [stockExchange, setStockExchange] = useState([]);
  const [politicalRadio, setPoliticalRadio] = useState("");
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  const [form0] = Form.useForm();

  const onChange = (e) => {
    setCompanytype(e.target.value);
  };
  function handlePoliticalRadio(e) {
    setPoliticalRadio(e.target.value);
  }
  useEffect(() => {
    const callTrustApi = async () => {
      await fetchData("hellosend/account/" + integId + "/campaign/registration?type=TRUST", "POST", null, appProperties).then(function (
        response
      ) {
        if (response) {
          response = JSON.parse(response);
          setStockExchange(response.data.stock_exchange);
        }
      });
      if (secondaryProfileStatus === "COMPLIANT" || secondaryProfileStatus === "IN_REVIEW" || secondaryProfileStatus === "TWILIO_APPROVED") {
        await fetchData(
          "hellosend/account/" + integId + "/profile/" + profileId + "?type=TRUST",
          "POST",
          formFields["Name & E-mail"],
          appProperties
        ).then(function (response) {
          if (response) {
            response = JSON.parse(response);
          }
        });
      }
    };
    callTrustApi();
  }, []);
  function saveForm() {
    form0.validateFields().then(function (value) {
      setContinueButtonLoading(true);
      console.log(value);
      fetchData("hellosend/account/" + integId + "/profile/" + profileId + "/company?type=TRUST", "POST", value, appProperties)
        .then(function (response) {
          response = JSON.parse(response);
          if (response?.data?.trust_evaluations?.status !== "COMPLIANT") {
            let registerResp = response?.data?.results[2];
            for (var i = 0; i < registerResp.length(); i++) {
              const failedFields = registerResp[i].fields.filter((field) => !field.passed);
              failedFields.map((failedField) => {
                form0.setFields([
                  {
                    name: failedField.object_field,
                    errors: [failedField.failure_reason],
                  },
                ]);
              });
            }
          } else {
            console.log(response);
            setCurrent(2);
            setContinueButtonLoading(false);
          }
        })
        .catch((error) => {
          setContinueButtonLoading(false);
        });
    });
  }
  return (
    <>
      <div className="container">
        <div className="m-3">
          <h4>Create an A2P Trust Product</h4>
          <div className="description10">
            "Generates an A2P Profile, which consists of a bundle of information to be submitted along with your Customer Profile to the Campaign
            Registry."
          </div>
        </div>
        <Form form={form0} layout="vertical" className="m-3" initialValues={{ ...trustType }}>
          <div>Company type Non-US nonprofits and governments should register as a private or public company.</div>
          <Form.Item
            name="company_type"
            rules={[
              {
                required: true,
                message: "Comapany Type is Mandatory.",
              },
            ]}
          >
            <Radio.Group onChange={onChange} value={companyType}>
              <Radio value={"private"}>Private</Radio>
              <Radio value={"public"}>Public</Radio>
              <Radio value={"usNonProfit"}>US Non Profit</Radio>
              <Radio value={"usGov"}>US Government</Radio>
            </Radio.Group>
          </Form.Item>
          {companyType === "private" ? null : companyType === "public" || trustType?.company_type === "public" ? (
            <>
              <Form.Item
                label="Stock Exchange"
                name="stock_exchange"
                rules={[
                  {
                    required: true,
                    message: "Stock Exchange is Mandatory.",
                  },
                ]}
              >
                <Select placeholder="choose Stock Exchange">
                  {stockExchange.map((obj, index) => (
                    <Select.Option key={index} value={obj.value}>
                      {obj.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Stock ticker"
                name="stock_ticker"
                rules={[
                  {
                    required: true,
                    message: "Stock ticker is Mandatory.",
                  },
                ]}
              >
                <Input placeholder="Enter Stock ticker" />
              </Form.Item>
            </>
          ) : // : companyType === "usNonProfit" ? (
          // <>
          //   <Form.Item
          //     label="Are you a 527 Political Organization"
          //     name="political"
          //     rules={[
          //       {
          //         required: true,
          //       },
          //     ]}
          //   >
          //     <Radio.Group
          //       value={politicalRadio}
          //       onChange={handlePoliticalRadio}
          //     >
          //       <Radio value={"yes"}>Yes</Radio>
          //       <Radio value={"no"}>No</Radio>
          //     </Radio.Group>
          //   </Form.Item>
          //   {politicalRadio === "yes" ? (
          //     <Form.Item
          //       label="Campaign Verify Token"
          //       name="verifyToken"
          //       rules={[
          //         {
          //           required: true,
          //         },
          //       ]}
          //     >
          //       <Input placeholder="Paste your token here" />
          //       All 527 Political Organizations (Federal, State, and Local)
          //       are required to provide a Campaign Verify token to verify
          //       their identity and engage with voters via text messaging. This
          //       token will qualify your organization to register using the
          //       Political campaign in the campaign registration step.
          //     </Form.Item>
          //   ) : null}
          // </>
          // )
          null}
          <Form.Item
            value="sss"
            name="Acknowledgement"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <div className="m-3 p-3 rounded">
              <Checkbox id="trustAcknowledgement">
                I concur with the assertion that the information provided above is accurate and valid.
              </Checkbox>
            </div>
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Button type="primary" loading={continueButtonLoading} style={{ backgroundColor: "#009EFD" }} onClick={saveForm} id="trustProduct">
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
export default CreateA2PTrustProduct;
