import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
// import Cards from "../voice/assets/Cards.svg";
import card from "../assets/svg/cards.svg";

const NoAccess = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const handleBackToHome = () => {
    props.setAccessDenied(false);
    navigate("/settings?" + queryParams);
  };

  return (
    <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Result
        icon={<div className="no-access-icon"></div>}
        extra={
          <div>
            <div>
              <h1 style={{ fontSize: "16px", fontWeight: "bold" }}>No Access</h1>
              <p style={{ fontSize: "14px" }}>Sorry, you don't have access to this page.</p>
            </div>
            {/* This button is disabled to prevent the user from going back to the settings page for the time being if needed we can revert this or remove it */}
            <Button onClick={handleBackToHome} className="hs-button-primary d-none" disabled={true}>
              Back to Settings
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default NoAccess;
