import { Typography } from "antd";

export default function LabelAndValue({ showOnlyPopup, label, children, hasError, direction = "vertical", labelClass = "" }) {
  const { Text } = Typography;
  if (!label) {
    return <div className={`${showOnlyPopup ? "position-absolute" : "w-100"}`}>{children}</div>;
  }
  return (
    <div
      id="hs-label-and-value"
      className={`${showOnlyPopup ? "position-absolute" : `d-grid gap-2`} ${direction === "horizontal" ? "hs-label-and-value-horizontal" : ""}`}
    >
      {label && <Text className={`hs-fw-400 ${labelClass} ${hasError ? "hs-color-red" : ""}`}>{label}</Text>}
      {children}
    </div>
  );
}
