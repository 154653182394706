import { Col, Row, Drawer, Form, List, Select, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../assets/css/voiceTeam.css";
import "../assets/css/base-styles.css";
import { useVoiceTeams } from "../hooks/useVoiceTeams";
import VoiceTeamCard from "./common/VoiceTeamCardComponent";
import Input from "../../components/custom/input/input/Input";
import HSelect from "../../components/custom/input/select/Select";
import { AppContext } from "../../context/AppContext";
import useUsers from "../../hooks/useUsers";
import CommonUserAvatar from "../CommonUserAvatar";
import { getCurrentFeature, voicePermissionOptions } from "../utils/VoiceUtils";
import { DeleteComponent, EmptyListView, isCurrentUserHasUserRolePermission, UpgradeAlertPopup } from "../../pages/utils/CommonVessels";
import VoiceTeamAgentsModal from "./common/VoiceTeamAgentsModal";
import HButton from "../../components/custom/input/button/Button";
import { useVoiceContext } from "../context/VoiceContext";
import { useLocation, useNavigate } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import { checkUserHasModuleAccess } from "../../pages/utils/commonUtils";

const VoiceTeam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [voiceTeams, setVoiceTeams] = useState([]);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamObj, setSelectedTeamObj] = useState({});
  const [isEditClick, setIsEditClick] = useState(false);
  const [viewAgentsModalOpen, setViewAgentsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [addAgentSelectValue, setAddAgentSelectValue] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [hasPermissionToAddUser, setHasPermissionToAddUser] = useState(false);
  const { getVoiceTeams, createTeam, updateTeam, deleteTeamWithAgents } = useVoiceTeams();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);

  const [licensedUsers, setLicensedUsers] = useState([]);
  const [form] = Form.useForm();
  const [appProperties] = useContext(AppContext);
  const { fetchUsers } = useUsers();

  useEffect(() => {
    if (appProperties?.licenseObj?.allLicensedUserDetails && appProperties?.userId) {
      let hasUserRolePermission = checkUserHasModuleAccess(appProperties?.rolesBasedObject, "users");
      if (hasUserRolePermission) {
        setHasPermissionToAddUser(true);
      } else {
        setHasPermissionToAddUser(false);
      }
    }
  }, [appProperties, appProperties?.licenseObj?.allLicensedUserDetails, appProperties?.userId]);

  const handlePermissionChange = (value, osyncUserId) => {
    const updatePermission = (users) => users.map((user) => (user.osyncUserId === osyncUserId ? { ...user, voicePermission: value } : user));
    setSelectedUsers((prevUsers) => updatePermission(prevUsers));
  };
  const { fetchData } = useHttp();
  const handleAddAgents = (osyncUserId, Obj) => {
    setAddAgentSelectValue(Obj?.label);
    setUsersToDelete((prev) => {
      if (Array.isArray(prev)) {
        return prev.filter((id) => id !== osyncUserId);
      }
      return [];
    });
    const selectedUserObj = licensedUsers?.find((user) => user.osyncUserId === osyncUserId);
    if (osyncUserId != null) {
      if (selectedUserObj && !selectedUsers.some((user) => user.osyncUserId === selectedUserObj.osyncUserId)) {
        setSelectedUsers((prevSelected) => [...prevSelected, selectedUserObj]);
      }
    }
  };

  const fetchPipeDriveUsers = () => {
    return new Promise((resolve, reject) => {
      if (appProperties) {
        fetchUsers(appProperties)
          .then((response) => {
            if (response) {
              const updatedUsers = appProperties?.licenseObj?.allLicensedUserDetails?.map((user) => {
                const userData = response?.data?.find((item) => item.id === user.remoteUserId);
                return {
                  ...user,
                  ...userData,
                  firstName: userData?.firstName || user?.email?.split("@")[0],
                  voicePermission: 1,
                };
              });

              setLicensedUsers(updatedUsers);
            }
            resolve();
          })
          .catch((error) => {
            console.error("Error fetching PipeDrive users:", error);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  };

  useEffect(() => {
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    } else if (featureName === "Call" || featureName === "Message and Call") {
      setIsLoading(true);
      Promise.all([getVoiceTeams(), fetchPipeDriveUsers()])
        .then(([voiceTeamsResponse]) => {
          setVoiceTeams(voiceTeamsResponse);
        })
        .catch((error) => {
          console.error("Error in fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [callProperties.agentStatusValue, appProperties?.leftServiceId]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
    setAddAgentSelectValue("");
    setIsEditClick(false);
  };
  const handleDrawerClose = () => {
    setAddAgentSelectValue("");
    setIsEditClick(false);
    setIsDrawerOpen(false);
    form.resetFields();
    setSelectedUsers([]);
    setUsersToDelete([]);
  };
  const handleCreateTeam = async () => {
    if (selectedUsers.length <= 0) {
      message.warning("Please select at least one user to create a team.");
      return;
    }
    if (!form.getFieldValue("friendlyName")) {
      message.warning("Please provide a Friendly name to create a team.");
      return;
    }
    try {
      setButtonLoading(true);
      const payload = {
        friendlyName: form.getFieldValue("friendlyName"),
        agentList: selectedUsers,
      };
      const response = await createTeam(payload);
      setButtonLoading(false);
      if (response) {
        setAddAgentSelectValue("");
        setVoiceTeams((prevTeams) => [
          ...prevTeams,
          {
            voiceAgentsEntity: response.voiceAgentsEntity,
            friendlyName: response.friendlyName,
            teamMetrics: {
              onlineAgents: [],
              busyAgents: [],
              offlineAgents: response?.voiceAgentsEntity?.map((agent) => agent?.osyncUserId) || [],
            },
          },
        ]);
        setSelectedUsers([]);
        form.resetFields();
        setIsDrawerOpen(false);
        message.success("Team Created Successfully.");
      } else {
        message.error("Failed to create the team. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setButtonLoading(false);
      message.error("An error occurred while creating the team. Please try again.");
    }
  };
  const handleEditTeam = () => {
    try {
      setButtonLoading(true);
      const payload = {
        usersToAdd: selectedUsers,
        friendlyName: form.getFieldValue("friendlyName"),
        usersToDelete: usersToDelete,
      };
      if (selectedTeamId) {
        updateTeam(selectedTeamId, payload).then((response) => {
          if (response) {
            setVoiceTeams((prevTeams) =>
              prevTeams.map((team) =>
                team.teamId === response?.teamId
                  ? {
                      ...team,
                      voiceAgentsEntity: response?.voiceAgentsEntity,
                      friendlyName: response?.friendlyName,
                      teamMetrics: {
                        ...team.teamMetrics,
                        onlineAgents: response?.teamMetrics?.onlineAgents || [],
                        busyAgents: response?.teamMetrics?.busyAgents || [],
                        offlineAgents: response?.teamMetrics?.offlineAgents || [],
                      },
                    }
                  : team
              )
            );
            form.resetFields();
            setAddAgentSelectValue("");
            setButtonLoading(false);
            setIsDrawerOpen(false);
            setUsersToDelete([]);
            setSelectedUsers([]);
            message.success("Team Updated Successfully.");
          }
        });
      }
    } catch (error) {
      console.log("Error", error);
      setButtonLoading(false);
    }
  };

  const handleDeleteAgent = async (osyncUserId) => {
    if (selectedUsers.length <= 1) {
      message.error("At least one user must remain in the team.");
      return;
    } else {
      setUsersToDelete([...usersToDelete, osyncUserId]);
      setSelectedUsers((prev) => prev.filter((user) => user.osyncUserId !== osyncUserId));
    }
  };
  const onAddAgentsClick = (teamObj) => {
    setSelectedTeamId(teamObj?.teamId);
    setIsEditClick(true);
    form.setFieldValue("friendlyName", teamObj?.friendlyName);

    const existingAgentIds = teamObj?.voiceAgentsEntity?.map((agent) => agent.osyncUserId);

    const updatedSelectedUsers = licensedUsers
      .filter((user) => {
        const isAgent = existingAgentIds.includes(user.osyncUserId);
        const isAlreadySelected = selectedUsers.some((selectedUser) => selectedUser.osyncUserId === user.osyncUserId);
        return isAgent && !isAlreadySelected;
      })
      .map((user) => {
        const agentFromTeam = teamObj.voiceAgentsEntity.find((agent) => agent.osyncUserId === user.osyncUserId);
        return {
          ...user,
          voicePermission: agentFromTeam?.voicePermission ?? user.voicePermission,
        };
      });
    console.log("updatedSelectedUsers", updatedSelectedUsers);
    // if (updatedSelectedUsers) {
    //   setIsDrawerOpen(true);
    //   setSelectedUsers((prevSelected) => [...prevSelected, ...updatedSelectedUsers]);
    // }
    if (updatedSelectedUsers.length > 0) {
      setSelectedUsers((prevSelected) => {
        const newSelectedUsers = [...prevSelected, ...updatedSelectedUsers];
        setIsDrawerOpen(true);
        return newSelectedUsers;
      });
    }
  };

  const onViewAgentsClick = (teamObj) => {
    setViewAgentsModalOpen(true);

    const agentsDetails = teamObj.voiceAgentsEntity.map((agent) => {
      const licensedUser = licensedUsers.find((user) => user.osyncUserId === agent.osyncUserId);
      return { ...agent, ...licensedUser };
    });

    const onlineAgents = agentsDetails.filter((agent) => teamObj?.teamMetrics.onlineAgents.includes(agent.osyncUserId));
    const busyAgents = agentsDetails.filter((agent) => teamObj?.teamMetrics.busyAgents.includes(agent.osyncUserId));
    const offlineAgents = agentsDetails.filter((agent) => teamObj?.teamMetrics.offlineAgents.includes(agent.osyncUserId));

    setSelectedTeamObj({ onlineAgents, busyAgents, offlineAgents });
  };

  const showDeleteModalPopup = (teamId) => {
    setShowDeleteModal(true);
    setTeamToDelete(teamId);
  };
  const handleDeleteTeam = (teamId) => {
    if (teamId) {
      deleteTeamWithAgents(teamId)
        .then((response) => {
          const deletedTeam = response;
          setVoiceTeams((prevTeams) => prevTeams.filter((team) => team.teamId !== deletedTeam.teamId));
          message.success("Team deleted Successfully.");
        })
        .catch((error) => {
          console.error("Error deleting team:", error);
        });
    }
  };

  const handleModalClose = () => {
    setViewAgentsModalOpen(false);
  };

  const handleAddUserNavigate = () => {
    setCallProperties((prev) => ({
      ...prev,
      openUserPageDrawer: true,
    }));
    navigate("/settings/users" + location.search);
  };
  const handleRequestAccess = () => {
    fetchData(`plans/${"voice"}/access`, "PUT", null, appProperties).then((res) => {
      if (res) {
        console.log("res");
        setUpgradeAlertPopup(false);
      }
    });
  };
  return (
    <div className="p-lg-3">
      <Row justify="space-between" align="middle" className="pb-2">
        <Row justify={"center"} align={"middle"}>
          <Col className="voice-info-icon call-action-icons me-lg-2" style={{ cursor: "default" }}></Col>
          <Col>
            Where you can manage call teams, assign users, and set permissions for incoming and outgoing calls to know more
            <a
              href="https://help.oapps.xyz/portal/en/kb/articles/call-module-for-crm#Team_in_HelloSend"
              target="_blank"
              rel="noreferrer"
              className="custom-anchor"
            >
              {" "}
              Click here
            </a>
          </Col>
        </Row>
        <Row className="me-lg-3">
          <HButton
            buttonClassName="create-Team-Btn"
            size="l"
            onClick={handleDrawerOpen}
            prefixIcon={<div className="actionIconsSprite plusIconWhite" />}
          >
            Create Team
          </HButton>
        </Row>
      </Row>
      {isLoading ? (
        <div className="d-flex justify-content-center antd-loading-icon hs-min-h-68-vh hs-fs-32">
          <LoadingOutlined spin className="mt-5" />
        </div>
      ) : voiceTeams?.length > 0 && !isLoading ? (
        <VoiceTeamCard
          voiceTeams={voiceTeams}
          onAddAgentsClick={onAddAgentsClick}
          showDeleteModalPopup={showDeleteModalPopup}
          onViewAgentsClick={onViewAgentsClick}
        />
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column hs-min-h-68-vh">
          <div className="emptyTeamsPageIcon" />
          <div className="fw-bold">No Teams Record</div>
          <div className="hs-color-dark-grey mt-2">Your team's records will be displayed here once they are added</div>
          <div className="pt-2">
            <div className="fw-bold mt-2">Get Started with Team Setup</div>
            <ul className="mt-2 ps-3 hs-color-dark-grey">
              <li>Create a team to get started</li>
              <li>Assign users to your team to enable call features</li>
              <li>Manage your team's settings for incoming and outgoing calls</li>
            </ul>{" "}
          </div>
        </div>
      )}
      <Drawer
        rootClassName="createFlowDrawer"
        title={
          <>
            <div className="w-100 d-flex flex-row align-items-center justify-content-center">
              <div className="p-2 fs-6 hs-w-95">{isEditClick ? "Edit Team" : "Create Team"}</div>
              <div className="actionIconsSprite modalCloseIcon pt-2" onClick={handleDrawerClose}></div>
            </div>
          </>
        }
        closable={false}
        open={isDrawerOpen}
        width={510}
        footer={
          <HButton
            buttonClassName="w-100"
            onClick={() => {
              if (isEditClick) {
                handleEditTeam();
              } else {
                handleCreateTeam();
              }
            }}
            loading={buttonLoading}
          >
            {isEditClick ? "Update" : "Create"}
          </HButton>
        }
      >
        <Form form={form}>
          <Row>
            <Input
              placeHolder="Enter a friendly name"
              formItemName="friendlyName"
              formItemLabel="Friendly Name"
              required={true}
              errorMessage="Please provide a friendly name."
              autoFocus
              size="m"
            />
            <div className="mt-lg-4 w-100 d-flex justify-content-between">
              <div>Assign User</div>
              {hasPermissionToAddUser ? (
                <div className="hs-color-violet hs-fw-500 cursor-pointer" onClick={handleAddUserNavigate}>
                  {" "}
                  Add User
                </div>
              ) : null}
            </div>
            <HSelect
              customProps={{
                value: addAgentSelectValue,
                className: "mt-lg-2",
                onChange: (value, obj) => {
                  handleAddAgents(value, obj);
                },
              }}
              placeholder="Choose Agents"
              options={licensedUsers?.map((user) => ({
                value: user.osyncUserId,
                label: user?.firstName,
              }))}
            />
            {selectedUsers.length > 0 && (
              <div className="w-100 mt-lg-3">
                <div className="d-flex flex-row justify-content-between">
                  <span className="fs-14-fw-500">Users</span>
                  <span className="fs-14-fw-500">Access</span>
                  <span></span>
                </div>

                <List
                  className="drawerList mt-lg-2"
                  itemLayout="horizontal"
                  loading={isEditClick ? (selectedUsers.length > 0 ? false : true) : false}
                  dataSource={selectedUsers}
                  renderItem={(item, index) => {
                    return (
                      <List.Item
                        actions={[
                          <div
                            className="call-action-icons voiceAgentsDeleteIcon cursor-pointer me-lg-4"
                            onClick={() => handleDeleteAgent(item?.osyncUserId)}
                          />,
                        ]}
                      >
                        <Col span={10}>
                          <List.Item.Meta
                            avatar={<CommonUserAvatar name={item?.firstName} icon={item?.avatar} size={40} />}
                            title={<p className="mt-2 fs-14-fw-500">{item?.firstName}</p>}
                          />
                        </Col>
                        <Col>
                          <Select
                            // defaultValue={item?.voicePermission || 1}
                            value={item?.voicePermission}
                            rootClassName=" teams-permission-select"
                            className=" me-1 fs-6 mb-1"
                            suffixIcon={<div className="permissionDropDownIcon call-action-icons" />}
                            dropdownStyle={{
                              width: "160px",
                            }}
                            options={voicePermissionOptions}
                            onChange={(value) => {
                              handlePermissionChange(value, item.osyncUserId);
                            }}
                          ></Select>
                        </Col>
                      </List.Item>
                    );
                  }}
                  locale={{
                    emptyText: (
                      <EmptyListView icon={`emptyTeamsPageIcon display-inline-block`} tagLineTwo={`No Users added`} tagHeight={"43vh"} />
                    ),
                  }}
                />
              </div>
            )}
          </Row>
        </Form>
      </Drawer>

      <DeleteComponent
        confirmDeleteComponentTitle="Delete Team?"
        confirmDeleteIcon="hs-delete-icon"
        confirmDeleteBtnName="Delete"
        modalOpen={showDeleteModal}
        setModalOpen={setShowDeleteModal}
        record={teamToDelete}
        confirmDeleteComponent={"Deleting the team will remove it and unlink all users assigned to it. Are you sure you want to proceed?"}
        appProperties={appProperties}
        handleDelete={handleDeleteTeam}
      />
      <VoiceTeamAgentsModal
        viewAgentsModalOpen={viewAgentsModalOpen}
        setViewAgentsModalOpen={setViewAgentsModalOpen}
        handleModalClose={handleModalClose}
        selectedTeamObj={selectedTeamObj}
      />
      {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
    </div>
  );
};

export default VoiceTeam;
