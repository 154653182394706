import { useCallback } from "react";
import useHttp from "./useHttp";
export default function useRoles() {
  const { fetchData } = useHttp();

  const handleRequest = useCallback(
    async (method, payload = null) => {
      return new Promise((resolve, reject) => {
        fetchData("roles", method, payload, null)
          .then((response) => {
            if (response) {
              let responseData = JSON.parse(response);
              let data = responseData.data;
              resolve(data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [fetchData]
  );

  const getAllRoles = useCallback(() => {
    return new Promise((resolve, reject) => {
      resolve(handleRequest("GET"));
    });
  }, [handleRequest]);

  const createCustomRole = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        resolve(handleRequest("POST", payload));
      });
    },
    [handleRequest]
  );

  const updateRole = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        resolve(handleRequest("PUT", payload));
      });
    },
    [handleRequest]
  );

  const deleteRole = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        resolve(handleRequest("DELETE", payload));
      });
    },
    [handleRequest]
  );
  return {
    getAllRoles,
    createCustomRole,
    updateRole,
    deleteRole,
  };
}
