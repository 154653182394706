import { PlusOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Badge, Input, Table, Row, Col, Tooltip } from "antd";
import { useContext, useEffect, useState, useCallback } from "react";
import useHttp from "../../hooks/useHttp";
import "../../assets/css/activeNumbers.css";
import { AppContext } from "../../context/AppContext";
import editIcon from "../../assets/icons/editIcon.svg";
import smsIcon from "../../assets/icons/smsIcon.png";
import mmsIcon from "../../assets/icons/mmsIcon.png";
import BuyNumber from "./BuyNumber";
import { ASSIST_MAIL, urlParams } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { BuyFunds, EditMessageServiceId, LowCreditsWarning, TenDLCStatus } from "../utils/CommonVessels";
import { FundsMonitor } from "../utils/FundsMonitor";
import ServicesSidebar from "../services/ServicesSidebar";
import useLicense from "../../hooks/useLicense";
import HButton from "../../components/custom/input/button/Button";

export default function ActiveNumbers(props) {
  let { backToChannelsButton } = props;
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [showBuyNumber, setShowBuyNumber] = useState(false);
  const [a2pStatus, setA2pStatus] = useState(null);
  const [msgSidList, setMsgSidList] = useState([]);
  const { fetchData } = useHttp();
  const [tableData, setTableData] = useState();
  const [tableDataObj, setTableDataObj] = useState([]);
  const [brandStatus, setBrandStatus] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [BalanceCredits, setBalanceCredits] = useState(null);
  const navigate = useNavigate();
  const { fetchLicense } = useLicense();
  const isFormVisited = localStorage.getItem("10DlcVisited");

  const mapDataToTable = useCallback(
    (data) => {
      setTableData([]);
      setTableDataObj(data);
      let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
      let updatedData = data.map((obj) => {
        return {
          ...obj,
          name: (
            <div onClick={(e) => e.stopPropagation()}>
              <EditFriendlyName appProperties={appProperties} phoneNumber={obj.phoneNumber} friendlyName={obj.friendlyName} />
            </div>
          ),
          sms: (
            <>
              {obj.smsEnabled !== undefined && obj.smsEnabled === "true" ? (
                <img
                  src={smsIcon}
                  alt="smsIcon"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                "--"
              )}
            </>
          ),
          mms: (
            <>
              {obj.mmsEnabled !== undefined && obj.mmsEnabled === "true" ? (
                <img
                  src={mmsIcon}
                  alt="mmsIcon"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                "--"
              )}
            </>
          ),
          messagingServiceId: (
            <div onClick={(e) => e.stopPropagation()}>
              <EditMessageServiceId
                msgSidList={msgSidList}
                friendlyName={obj.friendlyName}
                phoneNumber={obj.phoneNumber}
                messageServiceId={obj.messageServiceId}
                integId={rightServiceObj?.integProps?.integId}
              />
            </div>
          ),
          a2pStatus: (
            <span>
              {obj.a2pStatus === undefined ? (
                <Tooltip
                  title={
                    <span className="a2pStatusTip">
                      Your number remains unverified at the moment. It will be activated only after the completion of the 10DLC review process.
                    </span>
                  }
                  color="white"
                >
                  <Badge status="error" />
                  <span className="ms-2">Not Verified</span>
                </Tooltip>
              ) : (
                <>
                  <Badge status="success" />
                  <span className="ms-2">{obj.a2pStatus}</span>
                </>
              )}
            </span>
          ),
        };
      });
      setTableData(updatedData);
    },
    [appProperties, msgSidList]
  );

  useEffect(() => {
    const callApi = async () => {
      let apiUrl = "omessage/" + integId + "/savedNumbers";

      await fetchData(apiUrl, "GET", null, appProperties)
        .then(function (response) {
          response = JSON.parse(response);
          let respData = response.data.data;
          fetchData(`omessage/${integId}/numbers`, "GET", null, appProperties)
            .then((response) => {
              const responseData = JSON.parse(response);
              console.log(responseData, "response");
              if (responseData?.data?.messagingServices) {
                let getMsgServicesLists = responseData.data.messagingServices;
                const msgSidsList = getMsgServicesLists.map((service) => ({
                  friendlyName: service.friendlyName,
                  sid: service.sid,
                }));
                setMsgSidList(msgSidsList);
                mapDataToTable(respData);
                setTableDataObj(respData);
              } else {
                mapDataToTable(respData);
                setTableDataObj(respData);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          let status = respData.map((obj) => obj.a2pStatus);
          setA2pStatus(status);
          if (respData?.length > 0) {
            setAppProperties({ ...appProperties, phoneList: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      await fetchData("hellosend/account/" + integId + "/campaign/profiles", "GET", null, appProperties).then(function (response) {
        if (response) {
          response = JSON.parse(response);

          setBrandStatus(response.data?.steps?.[2]?.brand?.status);
          if (response.data.steps !== undefined) {
            setCampaignStatus(response.data?.steps[4]?.campaign?.campaignStatus);
            if (
              response.data.steps[4]?.campaign?.campaignStatus === "FAILED" &&
              response.data.steps[4]?.campaign?.errors[0]?.description.includes("unknown")
            ) {
              setCampaignStatus("UNKNOWNFAILED");
            }
          }
        }
      });
      await fetchLicense(appProperties).then(function (licenseData) {
        console.log("licenseData>>>>>>", licenseData);
        setAppProperties((prev) => ({ ...prev, licenseObj: licenseData }));
      });
    };

    let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
    let integId = rightServiceObj?.integProps?.integId;
    if (integId !== undefined && appProperties?.apps) {
      callApi();
    }
  }, [appProperties?.installedApps, fetchData]);

  useEffect(() => {
    if (appProperties.licenseObj != null) {
      let licenseInfo = appProperties.licenseObj.licenseDetails;
      if (licenseInfo !== null && licenseInfo !== undefined) {
        setBalanceCredits(licenseInfo?.creditsPurchased - licenseInfo?.creditsConsumed);
      }
    }
  }, [appProperties.licenseDetails]);

  function closeDrawer() {
    setShowBuyNumber(false);
  }

  const columns = [
    {
      title: "Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      // ellipsis: true,
    },

    {
      title: "10 DLC",
      dataIndex: "a2pStatus",
      // ellipsis: true,
      key: "a2pStatus",
    },
    {
      title: "Friendly Name",
      // ellipsis: true,
      width: "16%",
      dataIndex: "name",
      key: "name",
    },

    {
      ellipsis: true,
      title: "Messaging Service ID",
      dataIndex: "messagingServiceId",
      key: "messagingServiceId",
      width: "25%",
    },

    {
      // ellipsis: true,
      title: "Capabilities",
      children: [
        {
          title: "SMS",
          dataIndex: "sms",
          key: "sms",
        },
        {
          title: "MMS",
          dataIndex: "mms",
          key: "mms",
        },
      ],
    },
  ];
  return (
    <>
      {BalanceCredits < 10 && appProperties?.licenseObj?.licenseDetails?.creditsPurchased > 0 && <LowCreditsWarning />}
      {tableData?.length > 0 && (
        <>
          <TenDLCStatus
            appProperties={appProperties}
            brandStatus={a2pStatus}
            a2pStatus={a2pStatus}
            campaignStatus={campaignStatus}
            serviceName={"helloSend"}
          />
        </>
      )}
      <BuyNumber tableData={tableDataObj} open={showBuyNumber} updateTableData={mapDataToTable} closeDrawer={closeDrawer} />

      <div className="pt-4 w-100">{backToChannelsButton}</div>
      <Row className="d-flex" style={{ marginTop: tableData?.length > 0 ? "" : "" }}>
        {/* {appProperties?.serviceInstalled && (
          <Col span={4} className="ps-4">
            <ServicesSidebar />
          </Col>
        )} */}
        <Col style={{ width: "98%" }}>
          <Row className="d-flex m-auto w-100 mt-3">
            <Col className="m-2">
              {(!a2pStatus || Array.isArray(a2pStatus)) && tableData?.length > 0 && (
                <Badge dot={!isFormVisited} style={{ top: 0, right: 0 }}>
                  <div
                    className="actionIconsSprite tenDlc-icon"
                    span={1}
                    onClick={() => {
                      localStorage.setItem("10DlcVisited", true);
                      navigate("/channels/textsms/helloSend/10DLC?" + urlParams);
                    }}
                    style={{ cursor: "pointer" }}
                  ></div>
                </Badge>
              )}
            </Col>
            <Col className="d-flex align-items-center pe-3" style={{ marginLeft: "auto" }}>
              <FundsMonitor popOver={true} />
            </Col>
            <Col>
              <HButton
                buttonClassName="hs-fs-13 hs-fw-600"
                size="l"
                onClick={() => {
                  setShowBuyNumber(true);
                }}
                prefixIcon={<PlusOutlined style={{ fontSize: "1rem", fontWeight: 900 }} />}
              >
                Buy number
              </HButton>
            </Col>
          </Row>
          <Table
            onRow={() => ({
              onClick: () => navigate("/channels/textsms/helloSend/10DLC?" + urlParams),
            })}
            scroll={{
              x: 900,
            }}
            loading={tableData !== undefined ? false : true}
            align="center"
            className="activeNumbersTable mt-3 ms-3 me-3"
            pagination={false}
            columns={columns}
            dataSource={tableData}
            rowClassName="fixed-row-height"
            expandable
          ></Table>
        </Col>
      </Row>
    </>
  );
}

export const EditFriendlyName = (props) => {
  const [newName, setNewName] = useState(props.friendlyName);
  const [editName, setEditName] = useState(true);
  const [hover, setHover] = useState(false);
  const [appProperties] = useContext(AppContext);
  let rightServiceObj = appProperties.installedApps?.find((obj) => obj?.right?.service_name === "hello_send");
  let integId = rightServiceObj?.integProps?.integId;

  const { fetchData } = useHttp();
  const changeFriendlyName = (e, index) => {
    if (e.key === "Enter") {
      let body = {
        alphaSender: "false",
        friendlyName: e.target.value,
        isMessagingService: "false",
        phoneNumber: props.phoneNumber,
      };
      fetchData("omessage/" + integId + "/updatePhone", "PUT", body, appProperties).then(function (response) {
        console.log(response);
        setHover(false);
        setEditName(true);
        setNewName(e.target.value);
      });
    }
  };
  function FriendlyNameChange() {
    return (
      <>
        {editName ? (
          <img
            src={editIcon}
            alt="editIcon"
            style={{
              paddingLeft: "40%",
              paddingRight: "40%",
              height: 20,
              cursor: "pointer",
            }}
            onMouseOut={() => {
              setHover(false);
            }}
            onClick={() => {
              setEditName(false);
            }}
          />
        ) : (
          <Input className="hs-bg-off-white focus-border" style={{ width: 100 }} placeholder="content" onKeyDown={changeFriendlyName} />
        )}
      </>
    );
  }
  return (
    <>
      {hover ? (
        <FriendlyNameChange />
      ) : (
        <span
          style={{ width: "100%" }}
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseOut={() => {
            setHover(false);
          }}
        >
          {newName}
        </span>
      )}
    </>
  );
};
