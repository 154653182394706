import React, { useEffect, useState, useCallback } from "react";
import { Col, Drawer, Row, Tabs, Select, Divider } from "antd";
import "../assets/css/omessage.css";
import useRoles from "../hooks/useRoles.jsx";
import "./../assets/css/users.css";
import Input from "../../src/components/custom/input/input/Input.jsx";
import LabelAndValue from "../components/custom/form/LabelAndValue.jsx";
import { failureNotificationWithLink, successNotifyWithDescription } from "./utils/CommonNotifications";
import { useRolesContext } from "../context/RolesContext.js";
import HButton from "../components/custom/input/button/Button.jsx";

const INITIAL_MODULE_STATE = {
  USERS: { accessLevel: 0, fullAccess: false },
  INBOX: { accessLevel: 0, fullAccess: false },
  CAMPAIGN: { accessLevel: 0, fullAccess: false },
  AUTOMATION: { accessLevel: 0, fullAccess: false },
  TEMPLATES: { accessLevel: 0, fullAccess: false },
  "ADMIN SETTINGS": { accessLevel: 0, fullAccess: false },
  CHANNELS: { accessLevel: 0, fullAccess: false },
  "BLOCKED NUMBERS": { accessLevel: 0, fullAccess: false },
  TEAMS: { accessLevel: 0, fullAccess: false },
  "CALL HISTORY": { accessLevel: 0, fullAccess: false },
};

const MODULE_PERMISSIONS = {
  CAMPAIGN: {
    0: "Users will not have permission to create or access any campaigns.",
    1: "Users can create campaigns and access only their own.",
    2: "Users can create and access all campaigns within the organization.",
  },
  AUTOMATION: {
    0: "Users will not have permission to create or access automation.",
    1: "Users can create automation workflows and access only their own.",
    2: "Users can create and access all automation workflows within the organization.",
  },
  INBOX: {
    0: "Users will not have permission to view or use the Inbox.",
    1: "Users can access only the conversations they have participated in.",
    2: "Users can access all conversations within the organization.",
  },
  TEMPLATES: {
    0: "Users can view existing templates for sending messages but cannot edit or create new ones",
    1: "Users can create private templates and edit their own records. They can also view both owned and team templates.",
    2: "Users can create, edit, and access team templates within the organization.",
  },
  "ADMIN SETTINGS": {
    0: "Restricts access to admin settings.",
    2: "Allows users to edit admin-level settings.",
  },
  USERS: {
    0: "Restricts user management access.",
    2: "Allows users to add/edit team members in HelloSend.",
  },
  CHANNELS: {
    0: "Restricts channel management access.",
    2: "Allows users to manage all communication channels.",
  },
  "BLOCKED NUMBERS": {
    0: "Restricts blocked number access.",
    2: "Allows users to block/unblock numbers",
  },
  TEAMS: {
    0: "Restricts access to team management.",
    2: "Allows users to manage team settings and configurations.",
  },
  "CALL HISTORY": {
    0: "Restricts access to call history records.",
    2: "Allows users to view and manage call history records.",
  },
};

const getPermissionDescription = (module, value) => {
  const moduleKey = module.toUpperCase();
  return MODULE_PERMISSIONS[moduleKey]?.[value] || "";
};

const PERMISSION_TYPES = {
  MESSAGING: [
    { value: "0", label: "No Access" },
    { value: "1", label: "Owned Records" },
    { value: "2", label: "All Records" },
  ],
  GENERAL: [
    { value: "0", label: "Hide" },
    { value: "2", label: "Show" },
  ],
};

const TABS_CONFIG = {
  MESSAGE: {
    key: "1",
    tab: "Message",
    permissions: [
      { module: "Inbox", type: "MESSAGING" },
      { module: "Campaign", type: "MESSAGING" },
      { module: "Automation", type: "MESSAGING" },
      { module: "Templates", type: "MESSAGING" },
    ],
  },
  CALL: {
    key: "2",
    tab: "Call",
    permissions: [
      { module: "Teams", type: "GENERAL" },
      { module: "Call History", type: "GENERAL" },
    ],
  },
  SETTINGS: {
    key: "3",
    tab: "Settings",
    permissions: [
      { module: "Admin Settings", type: "GENERAL" },
      { module: "Channels", type: "GENERAL" },
      { module: "Blocked Numbers", type: "GENERAL" },
      { module: "Users", type: "GENERAL" },
    ],
  },
};

const AddRoles = React.memo(
  ({
    isDrawerOpen,
    setIsDrawerOpen,
    userModal,
    setUsersList,
    setRoles,
    roleNameProp = "",
    roleIdProp = "",
    roleModuleProp = { modules: INITIAL_MODULE_STATE },
    roleDescriptionProp = "",
    modulesList = [],
    setRoleModule,
    setRoleNameProp,
    roles,
    setHideUpdateRoleButton,
    hideUpdateRoleButton,
  }) => {
    const [rolesProperties] = useRolesContext();
    const { createCustomRole, updateRole } = useRoles();
    const [loading, setLoading] = useState(false);
    const [roleName, setRoleName] = useState(roleNameProp);
    const [rolesData, setRolesData] = useState(roleModuleProp);
    const [isDuplicateRole, setIsDuplicateRole] = useState(false);
    const [roleNameError, setRoleNameError] = useState(false);

    const handleClose = useCallback(() => {
      setIsDrawerOpen(false);
      setUsersList([]);
      setRolesData();
      setRoleName("");
      setRoleModule?.();
      setRoleNameProp?.("");
      setHideUpdateRoleButton(false);
    }, [setIsDrawerOpen, setUsersList, setRoleModule, setRoleNameProp, setHideUpdateRoleButton]);

    useEffect(() => {
      if (userModal === "AddUsers") {
        const isDuplicate =
          roles?.some((role) => role?.roleName?.toLowerCase().trim() === roleName?.toLowerCase().trim()) ||
          roleName?.toLowerCase().includes("account owner");
        setIsDuplicateRole(isDuplicate);
      }
    }, [roleName, roles, userModal]);

    const handlePermissionChange = useCallback((value, module, type) => {
      const moduleUpper = module.toUpperCase();
      const numericValue = Number(value);

      const permissionConfig = {
        TEMPLATES: {
          0: { accessLevel: 1, fullAccess: false },
          1: { accessLevel: 2, fullAccess: false },
          2: { accessLevel: 2, fullAccess: true },
        },
        MESSAGING: {
          0: { accessLevel: 0, fullAccess: false },
          1: { accessLevel: 2, fullAccess: false },
          2: { accessLevel: 2, fullAccess: true },
        },
      };

      // Get permission configuration based on module and type
      const config = moduleUpper === "TEMPLATES" ? permissionConfig.TEMPLATES : type === "MESSAGING" ? permissionConfig.MESSAGING : null;

      // Default to existing value if no specific configuration found
      const { accessLevel, fullAccess } = config?.[value] || {
        accessLevel: numericValue,
        fullAccess: false,
      };

      setRolesData((prev) => ({
        ...prev,
        modules: {
          ...prev.modules,
          [moduleUpper]: { accessLevel, fullAccess },
        },
      }));
    }, []);

    const handleSubmit = async () => {
      if (!roleName) {
        setRoleNameError(true);
        return;
      }

      const updatedRolesData = {
        ...rolesData,
        roleName,
        roleDescription: roleDescriptionProp,
        roleId: roleIdProp,
      };

      if (updatedRolesData.modules.INBOX.accessLevel !== 0) {
        updatedRolesData.modules.INBOX.accessLevel = 2;
      }

      setLoading(true);
      try {
        const action = userModal === "UpdateRoles" ? updateRole : createCustomRole;
        const response = await action(updatedRolesData);

        if (response?.data?.[0]) {
          const responseData = response.data[0];

          setRoles((prev) =>
            userModal === "UpdateRoles"
              ? prev.map((role) => (role.roleId === responseData.roleId ? responseData : role))
              : [...prev, responseData]
          );

          if (response?.errorMessage) {
            failureNotificationWithLink(userModal === "UpdateRoles" ? "Update Role" : "Create Role", response.errorMessage);
          } else {
            successNotifyWithDescription(
              userModal === "UpdateRoles" ? "Update Role" : "Create Role",
              `Role ${userModal === "UpdateRoles" ? "updated" : "created"} successfully.`
            );
          }

          if (userModal === "AddUsers") {
            rolesProperties.rolesBasedObject[responseData.roleId] = responseData.roleName;
          }
          handleClose();
        }
      } catch (err) {
        console.error(`Error ${userModal === "UpdateRoles" ? "updating" : "creating"} role:`, err);
      } finally {
        setLoading(false);
      }
    };

    const renderPermissionSelect = ({ module, type }) => {
      const moduleKey = module.toUpperCase();
      const moduleData = rolesData?.modules[moduleKey] || INITIAL_MODULE_STATE[moduleKey];
      // Centralized permission mapping configuration
      const permissionMapping = {
        TEMPLATES: {
          "1,false": "0", // No Access
          "2,false": "1", // Owned Records
          "2,true": "2", // All Records
        },
        MESSAGING: {
          "0,false": "0", // No Access
          "2,false": "1", // Owned Records
          "2,true": "2", // All Records
        },
        GENERAL: {
          "0,false": "0", // No Access
          "2,false": "2", // All Records
        },
      };

      // Determine default value using a more concise approach
      const defaultValue = (() => {
        const mappingKey = type === "TEMPLATES" ? "TEMPLATES" : type === "MESSAGING" ? "MESSAGING" : "GENERAL";

        const key = `${moduleData?.accessLevel},${moduleData?.fullAccess}`;
        return permissionMapping[mappingKey][key] || "0";
      })();

      const currentDescription = getPermissionDescription(module, defaultValue);

      return (
        <div key={module}>
          <LabelAndValue
            direction="horizontal"
            label={module}
            labelClass="w-25 mt-1"
            children={
              <div className="w-100 mt-10">
                <Select
                  options={PERMISSION_TYPES[type]}
                  onChange={(value) => handlePermissionChange(value, module, type)}
                  value={defaultValue}
                  style={{ width: "100%" }}
                  suffixIcon={<div className="actionIconsSprite customComponentDropDownIcon" />}
                  dropdownStyle={{
                    border: "none",
                    boxShadow: "none",
                    padding: "4px 0",
                  }}
                  popupClassName="custom-select-dropdown"
                />
                <Divider style={{ margin: "12px 0" }} />
                <div
                  style={{
                    fontSize: "12px",
                    color: "#666",
                    padding: "0 4px",
                    minHeight: "32px",
                    lineHeight: "16px",
                  }}
                >
                  {currentDescription}
                </div>
              </div>
            }
          />
        </div>
      );
    };

    return (
      <Drawer
        className="userDrawer"
        title={`${userModal === "AddUsers" ? (userModal === "View" ? "View role" : "Role") : "Edit Role"} Management`}
        open={isDrawerOpen}
        onClose={handleClose}
        destroyOnClose
        placement="right"
        width="40rem"
        footer={
          hideUpdateRoleButton ? null : (
            <div className="d-flex justify-content-end gap-2">
              <HButton buttonClassName="rolesBtn cancelRolesBtn" size="l" onClick={handleClose} type="outline" variant="cancel">
                Cancel
              </HButton>

              <HButton
                buttonClassName="rolesBtn"
                size="l"
                onClick={handleSubmit}
                loading={loading}
                disabled={isDuplicateRole || hideUpdateRoleButton}
              >
                {userModal === "AddUsers" ? "Create" : "Update"}
              </HButton>
            </div>
          )
        }
      >
        <div className="userModel">
          <Row>
            <Col xs={24} md={24}>
              <div className="mt-2">
                <Input
                  formItemLabel="Role Name"
                  id="roleName"
                  onChange={(e) => {
                    setRoleName(e.target.value);
                    setRoleNameError(false);
                  }}
                  value={roleName}
                  errorMessage={isDuplicateRole || roleNameError}
                  placeholder={"Role Name"}
                  customClasses={isDuplicateRole || roleNameError ? "hs-input-has-error" : ""}
                />
              </div>
              <span>
                {roleNameError ? (
                  <small className="hs-color-red">Role name is required</small>
                ) : isDuplicateRole ? (
                  <small className="hs-color-red">Role already exists</small>
                ) : (
                  ""
                )}
              </span>
            </Col>
          </Row>

          <Row className="mt-1">
            <Tabs defaultActiveKey="1" className="custom-tabs w-100">
              {Object.values(TABS_CONFIG).map(({ key, tab, permissions }) => (
                <Tabs.TabPane tab={tab} key={key}>
                  <div className="flex flex-col gap-4">{permissions.map((permission) => renderPermissionSelect(permission))}</div>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Row>
        </div>
      </Drawer>
    );
  }
);

export default AddRoles;
