import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { Chargebee_CHARGE_NAME } from "../../../constants/AppConstants";
import { AppContext } from "../../../context/AppContext";
import { InitializeChargebee, RegisterChargebee } from "../../../pages/utils/ChargebeeRegistration";
import BalanceCredits from "../BalanceCredits";

const SideBarCredits = () => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [chargeBeePlan, setChargeBeePlan] = useState(Chargebee_CHARGE_NAME);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isCreditAccessible, setIsCreditAccessible] = useState(true);
  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement("script");
      script.src = "https://js.chargebee.com/v2/chargebee.js";
      script.async = true;

      script.onload = () => {
        setScriptLoaded(true);
        InitializeChargebee();
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptLoaded]);

  useEffect(() => {
    if(appProperties?.controller?.isCreditAccessible){
      appProperties?.controller?.isCreditAccessible().then((isCreditAccessible) => {
        setIsCreditAccessible(isCreditAccessible);
      });
    }
  }, [appProperties?.controller]);

  function registerAgain() {
    RegisterChargebee("Omni", appProperties, setAppProperties);
  }
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      {isCreditAccessible ? (
        <Button
          className="w-100 h-100"
          type="text"
          data-cb-type="checkout"
          data-cb-item-0={chargeBeePlan}
          data-cb-item-0-quantity="1"
          onMouseEnter={registerAgain}
        >
          <BalanceCredits
            setBalanceBackground={() => {}}
            setShowOneTimeCharge={() => {}}
            setChargeBeePlan={setChargeBeePlan}
            chargeBeePlan={chargeBeePlan}
            handleCreditBalance={() => {}}
            sideBar={true}
          />
        </Button>
      ) : (
        <BalanceCredits
          setBalanceBackground={() => {}}
          setShowOneTimeCharge={() => {}}
          setChargeBeePlan={setChargeBeePlan}
          chargeBeePlan={chargeBeePlan}
          handleCreditBalance={() => {}}
          sideBar={true}
        />
      )}
    </div>
  );
};
export default SideBarCredits;
