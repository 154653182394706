import { Col, Divider, Radio, Row, Typography } from "antd";
import React from "react";
import ConfigPhone from "./ConfigPhone";
import { useState } from "react";
import VoiceConfigPhone from "./VoiceConfigPhone";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Step2 = ({ rightServiceObj, setRightServiceObj, setShowFinishButton, campaignStatus }) => {
  const pathname = window.location.pathname;

  return (
    <div className="mt-0 pt-0">
      <Row className="ms-4 d-flex align-items-center ">
        <Col className="ms-3 me-2 staticIconsSprite stepTwoAuthorizeIcon"></Col>
        <span>
          <Typography.Title
            style={{
              color: "#374151",
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: 500,
              paddingTop: 10,
            }}
          >
            Configure Phone
          </Typography.Title>
        </span>
      </Row>
      <Divider className="w-50 ms-auto me-auto mt-1 mb-1" style={{ minWidth: "85%", borderBlockStart: "1.3px solid #F3F3F3" }} />

      {!pathname.includes("voice") ? (
        <ConfigPhone
          rightServiceObj={rightServiceObj}
          setRightServiceObj={setRightServiceObj}
          setShowFinishButton={setShowFinishButton}
          campaignStatus={campaignStatus}
        />
      ) : (
        <VoiceConfigPhone rightServiceObj={rightServiceObj} setShowFinishButton={setShowFinishButton} />
      )}
    </div>
  );
};

export default Step2;
