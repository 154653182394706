import React, { createContext, useContext, useMemo, useState } from "react";

const RolesContext = createContext();
export const RolesProvider = ({ children }) => {
  const [rolesProperties, setRolesProperties] = useState({
    rolesBasedObject: "",
    currentRoleDetails: "",
  });
  const rolesObject = useMemo(() => [rolesProperties, setRolesProperties], [rolesProperties, setRolesProperties]);
  return <RolesContext.Provider value={rolesObject}>{children}</RolesContext.Provider>;
};

export const useRolesContext = () => {
  return useContext(RolesContext);
};
