import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, RouterProvider } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Clarity from "@microsoft/clarity";
import * as Sentry from "@sentry/react";
import { notification } from "antd";
import OTPModal from "./components/custom/OTPModal";
import AdminWrapper from "./components/main/AdminWrapper";
import Pricingbar from "./components/main/pricingbar";
import { ProviderObj } from "./context/AppContext";
import { RolesProvider } from "./context/RolesContext";
import createRouter from "./pages/router";
import { CommonLoadingV2 } from "./pages/utils/CommonVessels";
import PermissionDeniedModal from "./pages/utils/PermissionDeniedModal";
import { VoiceProvider } from "./voice/context/VoiceContext";

const App = () => {
  console.count("COMPONENT_RENDERED :: App");
  let pathName = window.location.pathname;
  const pathSegments = pathName.split("/");
  const [pricing] = useState(pathSegments?.indexOf("pricing") !== -1 ? true : false);
  const [permissionDeniedToSubAccount, setPermissionDeniedToSubAccount] = useState(false);
  const [admin] = useState(pathSegments?.indexOf("admin") !== -1 ? true : false);
  const [otpModalState, setOtpModalState] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [appPropsObject, setAppPropsObject] = useState({});
  console.log("Environment : ", process.env.NODE_ENV);
  console.log("Environment app : ", process.env.REACT_APP_ENV);
  const [notificationAPI, notificationContextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(pathSegments?.indexOf("content") !== -1 ? false : true);

  const loadingContextValue = {
    setLoading: setLoading,
    loading: loading,
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "development") {
      const projectId = process.env.REACT_APP_ENV === "production-1" ? "nd80pjlpbf" : "pygp7sgnfn";
      Clarity.init(projectId);
      console.log("Clarity init load success : ", projectId);

      window.$sleek = [];
      window.SLEEK_PRODUCT_ID = process.env.REACT_APP_SLEEKPLAN_PRODUCT_ID;
      const sleekPlanScript = document?.createElement("script");
      sleekPlanScript.src = "https://client.sleekplan.com/sdk/e.js";
      sleekPlanScript.async = true;
      document?.body?.appendChild(sleekPlanScript);

      return () => {
        document.body.removeChild(sleekPlanScript);
      };
    }
  }, []);
  const router = createRouter(loadingContextValue, null, accessDenied, setAccessDenied);
  return (
    <>
      {pricing ? (
        <Router>
          <Pricingbar setOtpModalState={setOtpModalState} otpModalState={otpModalState} />
        </Router>
      ) : // Router><Admin/></Router>
      admin ? (
        <AdminWrapper />
      ) : (
        <>
          {notificationContextHolder}
          {otpModalState && (
            <OTPModal appPropsObject={appPropsObject} open={otpModalState} setLoading={setLoading} setOtpModalState={setOtpModalState} />
          )}
          <PermissionDeniedModal permissionDeniedToSubAccount={permissionDeniedToSubAccount} />
          <RolesProvider>
            <ProviderObj
              setLoading={setLoading}
              setOtpModalState={setOtpModalState}
              setPermissionDeniedToSubAccount={setPermissionDeniedToSubAccount}
              setAppPropsObject={setAppPropsObject}
              setAccessDenied={setAccessDenied}
              accessDenied={accessDenied}
            >
              {/* <Router> */}
              {loading ? <CommonLoadingV2 /> : null}
              {/* <Space
                direction="vertical"
                style={{ width: "100%", background: "none" }}
                size={[0, 48]}
                > */}

              {/* </Space> */}
              {/* </Router> */}
              <VoiceProvider>
                <RouterProvider router={router} />
              </VoiceProvider>
            </ProviderObj>
          </RolesProvider>
        </>
      )}
    </>
  );
};

if (process.env.REACT_APP_ENV === "production-1") {
  Sentry.init({
    dsn: "https://9e6d564a937008aa6d41883bee1553f9@o4506630594363392.ingest.sentry.io/4506630597640192",
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/omessage.oapps.xyz\.io\/api/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Ignore transactions from URLs that match .clarity.ms
    denyUrls: [
      /https:\/\/[a-z]\.clarity\.ms\/collect/, // This regex will match any subdomain of clarity.ms with /collect
    ],
  });
}
export default App;
