import { useState } from "react";

const useCheckbox = ({ allowMultiSelect, indexOfDefaultSelectedValue = 0, items, handleBeforeChange, handleOnChange, groupLabel }) => {
  const [currentSelectedItems, setCurrentSelectedItems] = useState([items[indexOfDefaultSelectedValue]?.value]);

  const callOnChange = (values = []) => {
    if (!Array.isArray(values)) {
      return;
    }

    if (handleBeforeChange) {
      const canContinue = handleBeforeChange(values);
      if (!canContinue) {
        return;
      }
    }

    if (!allowMultiSelect) {
      values = getOnlyOneRecentlySelectedValue(values);
    }

    setCurrentSelectedItems(values ?? []);
    handleOnChange(values, groupLabel);
  };

  const getOnlyOneRecentlySelectedValue = (values) => {
    if (values?.length > 0) {
      return [values[values.length - 1]];
    }
  };

  return {
    currentSelectedItems,
    setCurrentSelectedItems,
    callOnChange,
  };
};

export default useCheckbox;
